import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';
import CampaignFeedback from '../components/CampaignFeedback';
import Hero from '../components/Hero';
import MenuMain from '../components/MenuMain';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import {Form} from "@evag/ec-react";
import SocialNetworks from "../components/SocialNetworks";
import Img from "gatsby-image";
import CampaignPressioneForm from "../components/CampaignPressioneForm";

export default function render({data}) {
    CampaignPressioneForm.channelId = data.site.meta.channelPressione;
    const title = "Pressione os Parlamentares! - " + data.site.meta.title
    const description = "Adicione sua entidade/movimento/coletivo na pressão que estamos fazendo aos parlamentares da Câmara de São Paulo a favor do nosso projeto SP é solo preto e indígena."

    return (
        <Layout>
            <SEO title={title}
                 ogimage={data.ogImage.childImageSharp.fixed}
                 description={description}/>

            <MenuMain data={data}/>

            <Hero
                logo={data.hero.childImageSharp.fluid}
                backgroundClass="pressione-background"
            />

            <main className="main">
                <div className="wrap">
                    <p>EXMO.(A) SR.(A) VEREADOR(A) ___________________,</p>

                    <p>Nós, entidades e coletivos dos movimentos negros, indígenas, acadêmicos e de periferia da cidade
                        de São Paulo, viemos por meio desta dialogar com seu mandato de vereador(a) sobre o projeto
                        47/2021 de autoria da nobre vereadora Luana Alves e co-autoria dos/as nobres vereadores/as
                        Juliana Cardoso, Elaine Mineiro do Quilombo periferico, Erika Hilton, Silvia da Bancada
                        Feminista, Professor Toninho Vespoli e Carlos Bezerra Jr, que determina o ensino acerca da
                        identidade cultural afrobrasileira e indígena; adição de monumentos e obras artísticas em alusão
                        aos povos negro e indígena; supressão de monumentos, estátuas, placas e quaisquer homenagens que
                        fazem menções a escravocratas, nazistas e eugenistas e outras disposições.</p>

                    <p>O resgate e manutenção da memória paulistana a partir da condição histórica de exploração e
                        resistência desses povos e seu importante protagonismo na construção e desenvolvimento da
                        cidade, faz com que seja necessário e urgente que esse projeto se torne lei. Esse debate já vem
                        acontecendo nos últimos anos a nível internacional em países como Estados Unidos, África do Sul,
                        Portugal e Alemanha. A tramitação e aprovação do PL 47/2021 é importante para que não só nós
                        entidades e coletivos dos movimentos negros, indígenas e periféricos brasileiros, mas toda
                        população da cidade possa ter acesso, compartilhar conhecimentos, desfrutar e se reconhecer na
                        história da cidade, de maneira coletiva, tolerante e harmoniosa. Acreditamos que todos nós temos
                        um objetivo comum, que é construir uma São Paulo verdadeiramente democrática, diversa e que seja
                        exemplo para outras cidades pelo mundo.</p>

                    <p>Assim, sabendo do seu compromisso com nossa cidade, solicitamos seu apoio e compromisso para
                        fazer avançar a votação desse PL e seu voto favorável. Podemos contar contigo?</p>

                    <p>Entidades que subscrevem essa carta: </p>
                </div>
            </main>

            <div id="sign" className="signatures default-background">
                <div className="wrap">
                    <h2 className="signatures-title">Quero adicionar minha entidade</h2>

                    <Form
                        forms={[
                            CampaignPressioneForm,
                            {
                                ...CampaignFeedback,
                                title: "Obrigada! Sua pressão é muito importante!",
                                description: "Acabei de pressionar os parlamentares da Câmara de São Paulo pelo projeto SP é Solo Preto e Indígena. Conheça e pressione você também!",
                            },
                        ]}
                    />
                </div>
            </div>

            <footer className="footer">
                <div className="wrap">
                    <div className="description">
                        <div className="info">
                            <h1>Uma iniciativa do mandato da vereadora Luana Alves</h1>
                        </div>
                        <div className="social-networks">
                            <SocialNetworks
                                twitter="luanapsol"
                                facebook="luanapsol"
                                instagram="luanapsol"
                                youtube="channel/UClZyv3izFgsogtxHI1Z9Cxw"
                                email="comunicaluanaalves@gmail.com"
                            />
                        </div>
                    </div>
                    <div className="logos">
                        <Img className="logo" alt="Punho - SP é Solo Preto!"
                             imgStyle={{
                                 objectFit: 'contain'
                             }}
                             style={{position: false}}
                             fluid={data.punho.childImageSharp.fluid}/>
                    </div>
                </div>
            </footer>
        </Layout>
    );
}

render.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channelPressione
        url
      }
    }

    hero: file(relativePath: { eq: "pressione/pressione.png" }) {
      childImageSharp {
        fluid(maxWidth: 778) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    logo: file(relativePath: { eq: "pretoindigena.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    ogImage: file(relativePath: { eq: "pressione/og-image.jpeg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          src
          width
          height
        }
      }
    }
    
    punho: file(relativePath: { eq: "punho-footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`;
